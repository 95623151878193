import { reactive, nextTick } from "vue";
import { flipCoins } from "@js/buttons.js";

export const store = reactive({
	calendlyOpen: false,
	navOpen: false,
});

export const mutations = {
	openCalendly() {
		store.calendlyOpen = true;
	},
	closeCalendly() {
		store.calendlyOpen = false;
	},
	toggleCalendly() {
		store.calendlyOpen = !store.calendlyOpen;
	},
	async openNav() {
		store.navOpen = true;
		await nextTick();
		flipCoins();
	},
	closeNav() {
		store.navOpen = false;
	},
	async toggleNav() {
		store.navOpen = !store.navOpen;
		await nextTick();
		flipCoins();
	},
};
