<template>
	<div ref="marqueeContainer" class="marquee-container relative">
		<div
			class="absolute left-0 inset-y-0 w-24 bg-gradient-to-l from-black/0 to-black transition-opacity duration-300 z-10"
			:class="{ 'opacity-100': isMarquee, 'opacity-0': !isMarquee }"
		></div>
		<div
			ref="marqueeContent"
			class="faux-p-sm marquee-content whitespace-nowrap"
			:class="{ 'marquee-move': isMarquee, 'marquee-static': !isMarquee }"
			v-if="!failed"
		>
			<div class="marquee-initial" ref="marqueeInitial">
				<template v-for="(item, key, index) in items">
					<span>{{ item }}: {{ rates[key] }}</span>
					<div v-if="loading" class="marquee-loading"></div>
					<div
						class="marquee-bullet"
						v-if="index < Object.keys(items).length - 1"
					></div>
				</template>
			</div>
			<template v-if="isMarquee">
				<div class="marquee-bullet"></div>
				<template v-for="(item, key, index) in items">
					<span>{{ item }}: {{ rates[key] }}</span>
					<div v-if="loading" class="marquee-loading"></div>
					<div class="marquee-bullet"></div>
				</template>
			</template>
		</div>
		<div v-else class="faux-p-sm marquee-content whitespace-nowrap">
			Failed to load, please refresh.
		</div>
		<div
			class="absolute right-0 inset-y-0 w-24 bg-gradient-to-r from-black/0 to-black transition-opacity duration-300 z-10"
			:class="{ 'opacity-100': isMarquee, 'opacity-0': !isMarquee }"
		></div>
	</div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick, watch } from "vue";

const loading = ref(true);
const failed = ref(false);

const items = ref({
	gold_oz: "Gold Eagle",
	gold_rate: "Gold Dollar",
	silver_rate: "Silver Eagle",
	goldback_rate: "Goldback",
});

const rates = ref([]);
const isMarquee = ref(false);
const marqueeContainer = ref(null);
const marqueeContent = ref(null);
const marqueeInitial = ref(null);

const fetchRates = async () => {
	let response;
	let data;

	const fiveMinutes = 60 * 5 * 1000; // Five minutes in milliseconds
	const localStorageKey = "upmaRates";
	const timestampKey = "upmaRatesTimestamp";

	try {
		// Check if data is already in localStorage and is fresh
		const storedData = localStorage.getItem(localStorageKey);
		const storedTimestamp = localStorage.getItem(timestampKey);

		if (
			storedData &&
			storedTimestamp &&
			Date.now() - storedTimestamp < fiveMinutes
		) {
			// Use the stored data if it's still fresh
			response = { ok: true };
			data = JSON.parse(storedData);
		} else {
			// Fetch new data from the API
			response = await fetch("https://api.upma.org/api/public/rates");
			data = await response.json();

			// Save the new data and the current timestamp in localStorage
			localStorage.setItem(localStorageKey, JSON.stringify(data));
			localStorage.setItem(timestampKey, Date.now().toString());
		}
	} catch (error) {
		console.error(error);
		loading.value = false;
		failed.value = true;
	}

	if (response?.ok && data) {
		const goldRate = data.gold_rate.replace(/[$,]/g, "");
		const goldOz = goldRate * 50;
		data.gold_oz = goldOz.toLocaleString("en-US", {
			style: "currency",
			currency: "USD",
		});

		loading.value = false;
		rates.value = data;
	} else {
		loading.value = false;
		failed.value = true;
	}
};

const checkMarquee = async () => {
	if (
		marqueeContainer.value &&
		marqueeContent.value &&
		marqueeInitial.value
	) {
		const containerWidth =
			marqueeContainer.value.getBoundingClientRect().width;
		const initialWidth = marqueeInitial.value.getBoundingClientRect().width;
		isMarquee.value = initialWidth > containerWidth;
		await nextTick();
		const contentWidth =
			marqueeContent.value.getBoundingClientRect().width /
			(isMarquee.value ? 2 : 1);
		marqueeContent.value.style.setProperty(
			"--marquee-width",
			`${contentWidth + 4}px`
		);
	}
};

const handleResize = () => {
	checkMarquee();
};

fetchRates();

watch(
	loading,
	(newVal) => {
		if (!newVal) {
			setTimeout(() => {
				checkMarquee();
			}, 1000);
		}
	},
	{ immediate: true }
);

onMounted(() => {
	window.addEventListener("resize", handleResize);
});

onBeforeUnmount(() => {
	window.removeEventListener("resize", handleResize);
});
</script>

<style scoped>
.marquee-container {
	@apply overflow-hidden;
}

.marquee-content {
	@apply max-w-none whitespace-nowrap;
	@apply font-sans font-medium text-gray-200;
	--marquee-width: 0px;
}

.marquee-content,
.marquee-initial {
	@apply inline-flex items-center gap-8;
}

.marquee-move {
	animation: marquee 15s linear infinite;
}

@keyframes marquee {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(calc(-1 * var(--marquee-width)));
	}
}

.marquee-loading {
	@apply w-16 h-16 bg-current;
	mask-image: url("/assets/graphics/loader.svg");
	mask-size: cover;
	@apply animate-spin;
}

.marquee-bullet {
	@apply flex-shrink-0 w-4 h-4 rounded-full;
	@apply bg-gray-200 opacity-30;
}
</style>
