<template>
	<form
		method="post"
		action=""
		accept-charset="UTF-8"
		class="relative"
		@submit.prevent="submit"
		:class="{ error: error }"
	>
		<slot></slot>
		<label for="email" class="sr-only">Email Address</label>
		<input
			ref="emailInput"
			type="email"
			id="email"
			name="fromEmail"
			placeholder="Enter your email"
			required
			v-model="email"
		/>
		<button
			type="submit"
			:class="{
				submitting: submitting,
				submitted: submitted,
				'bg-gradient-gold': !submitted,
			}"
			:disabled="email == '' || submitting || submitted"
		></button>
		<p v-if="error">There was an error, please try again.</p>
	</form>
</template>

<script setup>
import { ref, watch } from "vue";

const email = ref("");
const submitting = ref(false);
const submitted = ref(false);
const error = ref(false);

const emailInput = ref(null);

watch(submitting, (newVal) => {
	if (newVal) {
		setTimeout(() => {
			submitting.value = false;
			email.value = "";
			emailInput.value.blur();
			emailInput.value.placeholder = "Successfully subscribed!";
			submitted.value = true;
		}, 2000);
	}
});
const submit = async (event) => {
	submitting.value = true;

	let formData = new FormData(event.target);

	let response = await fetch("/", {
		method: "POST",
		body: formData,
	});

	plausible("Newsletter Subscription");
};
</script>

<style scoped>
input[type="email"] {
	@apply bg-transparent text-white;
	@apply w-full h-48 pl-20 pt-11 pb-13 pr-56;
	@apply rounded-full;
	@apply border border-gray-800;
	@apply transition-colors duration-300;

	&::placeholder {
		@apply text-gray-400;
		@apply transition-colors duration-300;
	}

	&:hover {
		@apply border-gray-600;
	}

	&:focus {
		@apply outline-none ring-0;
		@apply border-gold-300;

		&::placeholder {
			@apply text-gray-700;
		}
	}

	form.error & {
		@apply border-[#E17A7A];
	}
}

button[type="submit"] {
	@apply text-black;
	@apply w-32 h-32 rounded-full;
	@apply cursor-pointer;
	@apply absolute top-8 right-8;

	&::after {
		content: "";
		@apply absolute inset-0 m-auto;
		width: 24px;
		height: 24px;
		background-color: currentColor;
		mask-image: url("/assets/icons/24/arrowhead-right.svg");
	}

	&.submitting {
		&::after {
			mask-image: url("/assets/graphics/loader.svg");
			@apply animate-spin;
		}
	}

	&.submitted {
		@apply bg-green-900 text-green-300;

		&::after {
			mask-image: url("/assets/icons/24/check-badge.svg");
		}
	}
}

form.error p {
	@apply font-sans font-medium text-12 leading-150 text-center;
	@apply text-[#E17A7A] pt-1;
}
</style>
