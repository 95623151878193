export function updateCursor({ clientX: x, clientY: y }) {
	requestAnimationFrame(() => {
		document.documentElement.style.setProperty("--cursor-x", `${x}px`);
		document.documentElement.style.setProperty("--cursor-y", `${y}px`);
	});
}

export function setupInitialShimmers() {
	const containers = document.querySelectorAll(".shimmer-container");
	containers.forEach((container) => {
		const shimmer = container.classList.contains("shimmer-initial")
			? container
			: container.querySelector(".shimmer-initial");
		container.addEventListener("mousemove", function (e) {
			_moveInitialShimmers(e, container, shimmer);
		});
		if (container.previousElementSibling.id === "nav-container") {
			container.previousElementSibling.addEventListener(
				"mousemove",
				function (e) {
					_moveInitialShimmers(e, container, shimmer);
				}
			);
		}
	});
}

function _moveInitialShimmers(e, container, shimmer) {
	const rect = container.getBoundingClientRect();
	const x = e.clientX - rect.left;
	const y = e.clientY - rect.top;

	requestAnimationFrame(() => {
		shimmer.style.setProperty("--cursor-x", `${x}px`);
		shimmer.style.setProperty("--cursor-y", `${y}px`);
	});
}
