<template>
	<div class="relative z-50" @click.stop="closeCalendly">
		<div
			class="fixed inset-0 bg-black/25 transition-opacity"
			:class="[
				isOpen
					? 'opacity-100 duration-500 ease-out'
					: 'opacity-0 duration-300 ease-in pointer-events-none',
			]"
		></div>

		<div
			ref="modal"
			class="fixed inset-0 flex w-screen h-[calc(var(--vh,1vh)*100)] overflow-y-scroll ~pt-56/88 ~px-32/104 ~pb-56/120 transition-transform"
			:class="[
				isOpen
					? 'translate-y-0 duration-500 ease-out'
					: 'translate-y-[150vh] duration-300 ease-in',
			]"
		>
			<div
				class="w-full max-w-1000 min-h-600 m-auto bg-white rounded-[24px] flex justify-center items-center overflow-hidden"
			>
				<div ref="calendlyContainer" class="w-full"></div>
			</div>
		</div>
	</div>
</template>

<script setup>
import {
	ref,
	watch,
	computed,
	nextTick,
	onMounted,
	onBeforeUnmount,
} from "vue";
import { store, mutations } from "@js/vue/store.js";
import { lock, unlock } from "@js/body-scroll-lock.js";

const isOpen = computed(() => store.calendlyOpen);
const closeCalendly = mutations.closeCalendly;

const calendlyContainer = ref(null);

const initCalendly = () => {
	Calendly.initInlineWidget({
		url: "https://calendly.com/alpinegold/alpine-gold-consultation?hide_gdpr_banner=1",
		parentElement: calendlyContainer.value,
		resize: true,
		prefill: {},
		utm: {},
	});
};

const modal = ref(null);

watch(
	isOpen,
	async (newVal) => {
		await nextTick();
		if (newVal) {
			plausible("Book Appointment Opened");
			lock();
		} else {
			unlock();
		}
	},
	{ immediate: true }
);

const handleEsc = (event) => {
	if (isOpen.value && event.keyCode === 27) {
		closeCalendly();
	}
};

document.addEventListener("keydown", handleEsc);

onMounted(async () => {
	await nextTick();
	initCalendly();
});

onBeforeUnmount(() => {
	document.removeEventListener("resize", handleEsc);
});
</script>

<style scoped></style>
