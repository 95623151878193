export function flipCoins() {
	const allElements = document.querySelectorAll(
		".btn-primary:not(.btn-flippable)"
	);

	allElements.forEach((element) => {
		element.style.padding = "0";
		if (element.classList.contains("btn-outline")) {
			element.style.border = "none";
		} else {
			element.style.background = "none";
		}

		const text = element.innerText;
		element.innerHTML = "";
		const span = document.createElement("span");
		span.innerText = text;
		element.appendChild(span);

		const container = document.createElement("div");
		container.classList.add("btn-coin-container");
		element.appendChild(container);

		const coin = document.createElement("div");
		coin.classList.add("btn-coin");
		container.appendChild(coin);

		for (let i = 0; i < 5; i++) {
			const div = document.createElement("div");
			coin.appendChild(div);
		}

		const side = document.createElement("div");
		side.classList.add("btn-side");
		container.appendChild(side);

		element.classList.add("btn-flippable");

		element.addEventListener("mouseover", () => {
			container.classList.add("btn-flip");
			container.classList.add("btn-flipped");
		});

		element.addEventListener("focus", () => {
			if (!container.classList.contains("btn-flipped")) {
				container.classList.add("btn-flip");
			}
		});

		element.addEventListener("mouseleave", () => {
			container.classList.remove("btn-flipped");
		});

		element.addEventListener("animationend", () => {
			container.classList.remove("btn-flip");
		});
	});
}
