<template>
	<slot></slot>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
	const navContainer = document.querySelector("#nav-container");

	const handleScroll = () => {
		if (window.scrollY > 40) {
			navContainer.classList.add("scrolled");
		} else {
			navContainer.classList.remove("scrolled");
		}
	};

	window.addEventListener("scroll", handleScroll);
	handleScroll();
});
</script>

<style scoped></style>
