<template>
	<Teleport to="#app">
		<div id="mobile-nav-container" class="relative z-40">
			<transition
				enter-active-class="transition-opacity ease-in duration-300"
				enter-from-class="opacity-0"
				enter-to-class="opacity-100"
				leave-active-class="transition-opacity ease-out duration-200"
				leave-from-class="opacity-100"
				leave-to-class="opacity-0"
			>
				<div
					class="fixed inset-0 bg-black/25"
					v-if="isOpen"
					@click="closeNav"
				></div>
			</transition>

			<div class="fixed inset-0 pointer-events-none">
				<transition
					enter-active-class="transition-transform ease-in duration-300"
					enter-from-class="translate-x-full"
					enter-to-class="translate-x-0"
					leave-active-class="transition-transform ease-out duration-200"
					leave-from-class="translate-x-0"
					leave-to-class="translate-x-full"
				>
					<div
						ref="nav"
						id="mobile-nav"
						class="pointer-events-auto absolute top-[max(calc(theme(spacing.40)-var(--scroll-y)),0px)] right-0 w-full sm:max-w-480 overflow-y-scroll overflow-x-hidden bg-black p-40 flex flex-col gap-40"
						v-if="isOpen"
					>
						<div class="flex items-center gap-4 -my-8 -mr-8">
							<div class="flex-shrink-0 py-8 z-20 mr-auto">
								<slot name="logo"></slot>
							</div>
							<a
								href="https://member.upma.org/?referral=309434505493371378"
								target="_blank"
								class="btn-login md:hidden"
								>UPMA Account</a
							>
							<button
								@click="closeNav"
								class="rounded-full w-40 h-40 p-8 hocus:bg-white/10 transition-colors duration-300 focus:outline-none focus-visible:focus-block z-20"
							>
								<slot name="close"></slot>
							</button>
						</div>
						<slot
							:active-panel="activePanel"
							:open-panel="openPanel"
							:close-panel="closePanel"
							:scroll-panel="scrollPanel"
						></slot>
					</div>
				</transition>
			</div>
		</div>
	</Teleport>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted, onUnmounted } from "vue";
import { store, mutations } from "@js/vue/store.js";
import { lock, unlock } from "@js/body-scroll-lock.js";

const isOpen = computed(() => store.navOpen);
const closeNav = () => {
	mutations.closeNav();
	closePanel();
};

const nav = ref(null);

watch(
	isOpen,
	async (newVal) => {
		await nextTick();
		if (newVal) {
			lock();
			nav.value.style.setProperty("--scroll-y", `${window.scrollY}px`);
		} else {
			unlock();
		}
	},
	{ immediate: true }
);

const activePanel = ref(null);
const openPanel = (panel) => {
	activePanel.value = panel;
};
const closePanel = () => {
	activePanel.value = null;
};

const scrollPanel = () => {
	if (!nav.value) {
		return;
	}

	const container = nav.value.querySelector("#panel-container");
	const content = nav.value.querySelector("#panel");
	if (container && content) {
		const containerWidth = container.clientHeight;
		const contentWidth = content.scrollHeight;
		const scrollTop = content.scrollTop;
		const scrollBottom = contentWidth - containerWidth - scrollTop;
		if (scrollTop > 0) {
			content.classList.add("before:opacity-100");
			content.classList.remove("before:opacity-0");
		} else {
			content.classList.add("before:opacity-0");
			content.classList.remove("before:opacity-100");
		}
		if (scrollBottom > 0) {
			content.classList.add("after:opacity-100");
			content.classList.remove("after:opacity-0");
		} else {
			content.classList.add("after:opacity-0");
			content.classList.remove("after:opacity-100");
		}
	}
};

const handleJumpLinks = () => {
	if (!nav.value) {
		return;
	}

	const content = nav.value.querySelector("#panel");
	if (content) {
		const links = content.querySelectorAll("a");
		links.forEach((link) => {
			const currentURL = window.location.href.split("#")[0];
			const linkURL = link.href.split("#")[0];
			if (currentURL === linkURL && link.hash) {
				link.addEventListener("click", (event) => {
					closeNav();
				});
			}
		});
	}
};

watch(
	activePanel,
	async (newVal) => {
		if (newVal) {
			await nextTick();
			scrollPanel();
			handleJumpLinks();
		}
	},
	{ immediate: true }
);

const handleEsc = (event) => {
	if (isOpen.value && event.keyCode === 27) {
		closeNav();
	}
};

document.addEventListener("keydown", handleEsc);
window.addEventListener("resize", scrollPanel);

onUnmounted(() => {
	document.removeEventListener("keydown", handleEsc);
	window.removeEventListener("resize", scrollPanel);
});
</script>

<style scoped>
#mobile-nav {
	/* prettier-ignore */
	height: calc(calc(var(--vh, 1vh) * 100) - max(calc(theme(spacing.40) - var(--scroll-y)), 0px));
}
</style>
